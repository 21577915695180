/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const modalFacilityAddSlice = createSlice({
  name: 'modalFacilityAdd',
  initialState: {
    open: false,
  },
  reducers: {
    openFacilityAdd: (state) => {
      state.open = true;
    },
    closeFacilityAdd: (state) => {
      state.open = false;
    },
  },
});

export const { openFacilityAdd, closeFacilityAdd } = modalFacilityAddSlice.actions;
export default modalFacilityAddSlice.reducer;
