import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const SubListContent = ({ sublist, className }) => (
  <ul className={className}>
    {sublist.map((subItem) => (
      <li>{ReactHtmlParser(subItem)}</li>
    ))}
  </ul>
);

const ListContent = ({ content, className, headerClassName, pointClassName, subListClassName }) =>
  content.map(({ title, points }) => (
    <div className={className}>
      <h2 className={headerClassName}>{ReactHtmlParser(title)}</h2>
      <div className={pointClassName}>
        {points.map((item) => {
          const { sublist } = item;
          if (sublist) return <SubListContent sublist={sublist} className={subListClassName} />;
          return <div> {ReactHtmlParser(item)}</div>;
        })}
      </div>
    </div>
  ));

ListContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  pointClassName: PropTypes.string,
  subListClassName: PropTypes.string,
};

ListContent.defaultProps = {
  className: '',
  headerClassName: '',
  pointClassName: '',
  subListClassName: '',
};

SubListContent.propTypes = {
  sublist: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

SubListContent.defaultProps = {
  className: '',
};

export default ListContent;
