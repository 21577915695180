import React from 'react';
import PropTypes from 'prop-types';
import * as ReactModal from 'react-modal';
import { motion } from 'framer-motion';

if (typeof document !== 'undefined') ReactModal.setAppElement(document.getElementById('___gatsby'));

const Header = ({ children, requestClose, closeButton }) => (
  <div className="px-4">
    <div
      className={`flex items-center bg-white ${!children ? 'justify-end' : 'justify-between'}`}
      style={{ minHeight: '5rem' }}
    >
      {children && children}
      {closeButton && (
        <button
          type="button"
          onClick={requestClose}
          className="p-2 transition rounded-full hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
        >
          <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
            <path d="M12 13.415l4.95 4.95 1.414-1.415-4.95-4.95 4.95-4.95-1.415-1.413-4.95 4.95-4.949-4.95L5.636 7.05l4.95 4.95-4.95 4.95 1.414 1.414 4.95-4.95z" />
          </svg>
        </button>
      )}
    </div>
  </div>
);

const Body = ({ children }) => <div className="flex-grow px-4 overflow-y-auto">{children}</div>;

const Footer = ({ children }) => (
  <div className="px-4">
    <div className="flex items-center bg-white" style={{ minHeight: '5rem' }}>
      {children}
    </div>
  </div>
);

const Aside = ({ children, title, open, requestClose, left, maxWidth }) => (
  <ReactModal
    contentLabel={title}
    overlayClassName={`bg-black bg-opacity-75 fixed inset-0 z-50 sm:flex sm:flex-col ${
      left ? 'sm:items-start' : 'sm:items-end'
    }`}
    className={`w-screen h-screen outline-none sm:max-w-${maxWidth}`}
    isOpen={open}
    onRequestClose={requestClose}
  >
    <motion.div
      initial={{ opacity: 0, x: left ? '-100%' : '100%' }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.2 }}
      className="relative flex flex-col w-full h-full bg-white"
    >
      {children}
    </motion.div>
  </ReactModal>
);

Header.propTypes = {
  children: PropTypes.node,
  requestClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
};

Header.defaultProps = {
  children: undefined,
  closeButton: true,
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

Aside.Header = Header;
Aside.Body = Body;
Aside.Footer = Footer;

Aside.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  left: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

Aside.defaultProps = {
  title: 'Aside',
  left: false,
  maxWidth: 'sm',
};

export default Aside;
