import React, { useState } from 'react';

import Menu from './Menu';

import logo from 'src/assets/images/equilibre_logo.png';

const Navigation = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)

    return (
        <>
        <nav className="equilibre-navigation flex flex-wrap items-center justify-between fixed bg-white w-full z-50 overflow-visible lg:overflow-hidden" style={{ height: '82px', boxShadow: '0px 4px 34px rgba(0, 51, 44, 0.04)' }}>
            <div className="py-6 lg:py-0 flex flex-wrap items-center justify-between w-full">
                <div className="px-8 sm:px-8 md:px-12 xl:px-20 w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start items-center">
                    <a href="/#home">
                        <img
                            src={logo}
                            alt="Logo Equilibre"
                            className='objet-contain'
                            style={{
                                height: '20px'
                            }}
                        />
                    </a>
                    <div className='flex'>
                        <a
                            className="px-3 py-1 flex items-center text-white rounded font-bold hover:opacity-75 lg:hidden"
                            href="/#contact"
                            onClick={() => handler(false)}
                            style={{ backgroundColor: 'rgb(254, 172, 93)' }}
                        >
                            Contact
                        </a>
                        <button
                            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                                { !navbarOpen &&
                                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                                }
                                { navbarOpen &&
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                }
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    className={
                    "pb-6 lg:pb-0 px-6 sm:px-6 md:px-10 xl:px-20 lg:flex flex-grow items-center bg-white" +
                    (navbarOpen ? " flex" : " hidden")
                    }
                >
                    <Menu handler={setNavbarOpen} />
                </div>
            </div>
        </nav>
        </>
    );
};

export default Navigation;