import VERTICALS from '../../constants/verticals.json';
import { FLOWS as FLOW_NAMES, MSAL } from '../shared';

const VERTICAL = VERTICALS.VACCINE;

const DEPLOYMENT = {
  LOCAL: {
    ...MSAL.LOCAL,
    API_URL: 'http://localhost:3000/api',
  },
  DEV: {
    ...MSAL.DEV,
    REDIRECT_URI: 'https://vaccine-amba-dev.hs.sharecare.paris',
    API_URL: 'https://amba-back-dev.hs.sharecare.paris/api',
  },
  QA: {
    ...MSAL.QA,
    REDIRECT_URI: 'https://vaccine-amba-qa.hs.sharecare.paris',
    API_URL: 'https://amba-back-qa.hs.sharecare.paris/api',
  },
  PREPROD: {
    CLIENT_ID: '0f20b5a6-343e-4f71-8ceb-1c4c35f97ea3',
    AUTHORITY: 'https://vaccinehspreprod.b2clogin.com/vaccinehspreprod.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['vaccinehspreprod.b2clogin.com'],
    SCOPES: [
      'https://vaccinehspreprod.onmicrosoft.com/f689f02e-f139-4db7-96c6-d7d0482b305a/read',
      'https://vaccinehspreprod.onmicrosoft.com/f689f02e-f139-4db7-96c6-d7d0482b305a/write',
    ],
    REDIRECT_URI: 'https://vaccine-amba-preprod.hs.sharecare.paris',
    API_URL: 'https://vaccine-amba-back-preprod.hs.sharecare.paris/api',
  },
  PROD: {
    CLIENT_ID: '4e6356da-2561-4b12-9fe4-afd0639216c4',
    AUTHORITY: 'https://vaccinehealthsecurity.b2clogin.com/vaccinehealthsecurity.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['vaccinehealthsecurity.b2clogin.com'],
    SCOPES: [
      'https://vaccinehealthsecurity.onmicrosoft.com/cb8db0ca-47ea-436e-84d7-62a58451af3c/read',
      'https://vaccinehealthsecurity.onmicrosoft.com/cb8db0ca-47ea-436e-84d7-62a58451af3c/write',
    ],
    REDIRECT_URI: 'https://vaccine.hs.sharecare.com',
    API_URL: 'https://vaccine-amba-back.hs.sharecare.com/api',
  },
}[process.env.GATSBY_DEPLOYMENT];

const FLOWS = {
  [FLOW_NAMES.SIGNIN]: {
    scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
    authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_signin`,
  },
  [FLOW_NAMES.PROFIL]: {
    scopes: DEPLOYMENT.SCOPES,
    authority: `${DEPLOYMENT.AUTHORITY}/B2C_1_profile_editing`,
  },
  [FLOW_NAMES.PASSWORD]: {
    scopes: DEPLOYMENT.SCOPES,
    authority: `${DEPLOYMENT.AUTHORITY}/B2C_1_password_reset`,
  },
};

export default {
  VERTICAL,
  DEPLOYMENT,
  FLOWS,
};
