import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'formik';

const Search = ({ name, placeholder, namespaces, translate, setSearch, style }) => {
  const [{ value }, , { setValue }] = useField(name);
  const { t } = useTranslation(translate ? namespaces : 'common');

  return (
    <div className="relative w-full">
      <Field
        type="text"
        name={name}
        aria-labelledby={name}
        placeholder={translate ? t(placeholder) : placeholder}
        className="w-full py-2 pl-3 pr-8 text-sm transition border border-gray-300 rounded focus:outline-none focus:border-black disabled:pointer-events-none"
        style={{ ...style }}
      />
      {value !== '' && (
        <div className="absolute top-0 right-0 flex items-center h-full pr-2">
          <button
            type="button"
            onClick={() => {
              setSearch('');
              setValue('');
            }}
            className="flex text-gray-500 transition focus:outline-none hover:text-black focus:text-black"
          >
            <svg viewBox="0 0 24 24" width="22" height="22" className="stroke-current">
              <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  setSearch: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

Search.defaultProps = {
  placeholder: '',
  namespaces: [],
  translate: true,
  style: {},
};

export default Search;
