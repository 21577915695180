/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import store from 'src/app/store';

import CONFIG from './src/app/config';

import './src/translations/i18n';
import './src/styles/tailwind.scss';
import './src/styles/main.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const msalInstance = new PublicClientApplication(CONFIG.MSAL);

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>{element}</MsalProvider>
  </Provider>
);