import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import AccordionContext from '../AccordionContext';

const Body = ({ children }) => {
  const { open } = useContext(AccordionContext);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          key="content"
          initial="closed"
          animate="open"
          exit="closed"
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          transition={{ duration: 0.2 }}
          style={{ height: open ? 'auto' : 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
