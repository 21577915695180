/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    quote: false,
  },
  reducers: {
    openQuote: (state) => {
      state.quote = true;
    },
    closeQuote: (state) => {
      state.quote = false;
    },
  },
});

export const { openQuote, closeQuote } = modalSlice.actions;
export default modalSlice.reducer;
