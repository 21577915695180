import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AccordionContext from '../AccordionContext';

const Header = ({ children, className }) => {
  const { open, setOpen } = useContext(AccordionContext);

  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={`flex items-center w-full my-4 focus:outline-none ${className}`}
      >
        <span className="flex-grow font-semibold text-left">{children}</span>
        <svg
          viewBox="0 0 24 24"
          className={`w-6 h-6 fill-current transition transform ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        >
          <path d="M12 15.6L6.215 9.815 7.629 8.4 12 12.771l4.372-4.37 1.414 1.414L12 15.6z" />
        </svg>
      </button>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
