import VERTICALS from '../../constants/verticals.json';
import { FLOWS, MSAL } from '../shared';

const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

let { AUTHORITY, SCOPES } = MSAL[DEPLOYMENT];

if (DEPLOYMENT === 'PROD') {
  AUTHORITY = 'https://sharecareeuprod.b2clogin.com/sharecareeuprod.onmicrosoft.com';
  SCOPES = [
    'https://sharecareeuprod.onmicrosoft.com/68518e7a-1999-46ff-aa45-ecbbe3c58cea/read',
    'https://sharecareeuprod.onmicrosoft.com/68518e7a-1999-46ff-aa45-ecbbe3c58cea/write',
  ];
}

export default {
  VERTICAL: VERTICALS.DEKRA,
  DEPLOYMENT: {
    LOCAL: {
      ...MSAL.LOCAL,
      API_URL: 'http://localhost:3000/api',
    },
    DEV: {
      ...MSAL.DEV,
      REDIRECT_URI: 'https://dekra-amba-dev.hs.sharecare.paris',
      API_URL: 'https://amba-back-dev.hs.sharecare.paris/api',
    },
    QA: {
      ...MSAL.QA,
      REDIRECT_URI: 'https://dekra-amba-qa.hs.sharecare.paris',
      API_URL: 'https://amba-back-qa.hs.sharecare.paris/api',
    },
    PREPROD: {
      ...MSAL.PREPROD,
      REDIRECT_URI: 'https://dekra-amba-preprod.hs.sharecare.paris',
      API_URL: 'https://amba-back-preprod.hs.sharecare.paris/api',
    },
    PROD: {
      ...MSAL.PROD,
      CLIENT_ID: '886b72e4-3a6b-4d5b-b7e1-4677fbeb79b6',
      AUTHORITY,
      KNOWN_AUTHORITIES: ['sharecareeuprod.b2clogin.com'],
      REDIRECT_URI: 'https://dekra-eu-amba-prod.hs.sharecare.paris',
      SCOPES,
      API_URL: 'https://amba-back-eu-prod.hs.sharecare.paris/api',
    },
  }[process.env.GATSBY_DEPLOYMENT],
  FLOWS: {
    [FLOWS.SIGNIN]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_signup_signin`,
    },
    [FLOWS.SIGNUP]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_signup`,
    },
    [FLOWS.PROFIL]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_profile_editing`,
    },
    [FLOWS.PASSWORD]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_password_reset`,
    },
  },
};
