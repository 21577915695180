import * as VERTICALS from './environments';
import { FLOWS } from './shared';

const VERTICAL_CONFIG = VERTICALS[process.env.GATSBY_VERTICAL];

const CONFIG = Object.freeze({
  ...VERTICAL_CONFIG,
  MSAL: {
    auth: {
      clientId: VERTICAL_CONFIG.DEPLOYMENT.CLIENT_ID,
      authority: VERTICAL_CONFIG.FLOWS[FLOWS.SIGNIN].authority,
      knownAuthorities: VERTICAL_CONFIG.DEPLOYMENT.KNOWN_AUTHORITIES,
      redirectUri: VERTICAL_CONFIG.DEPLOYMENT.REDIRECT_URI,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      secureCookies: true,
    },
  },
});

export { default as schema } from './schema';
export default CONFIG;
