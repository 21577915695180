/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'formik';

const Select = ({ name, label, namespaces, translate, options, disabled }) => {
  const { t } = useTranslation(translate ? namespaces : 'common');
  const [field] = useField(name);

  return (
    <div
      className={`w-full flex flex-col text-sm ${
        disabled ? 'opacity-10 cursor-not-allowed' : 'opacity-100'
      }`}
    >
      <label id={name} htmlFor={name}>
        {translate ? t(label) : label}
      </label>
      <Field
        type="select"
        as="select"
        {...field}
        aria-labelledby={name}
        disabled={disabled}
        className="px-3 py-2 mt-1 transition border rounded appearance-none focus:outline-none disabled:pointer-events-none"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  namespaces: [],
  translate: true,
  disabled: false,
};

export default Select;
