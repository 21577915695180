/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const configLinkSlice = createSlice({
  name: 'configLink',
  initialState: {
    botLink: '',
    certificationLink: '',
    auditLink: '',
    dashboardLink: '',
    label: '',
  },
  reducers: {
    updateConfigLink: (state, action) => {
      const { botLink, certificationLink, auditLink, dashboardLink, label } = action.payload;
      state.botLink = botLink;
      state.certificationLink = certificationLink;
      state.auditLink = auditLink;
      state.dashboardLink = dashboardLink;
      state.label = label;
    },
  },
});

export const { updateConfigLink } = configLinkSlice.actions;
export default configLinkSlice.reducer;
