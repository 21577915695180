import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const Step = ({ children, schema }) => children;

Step.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.shape({}),
};

Step.defaultProps = {
  schema: null,
};

export default Step;
