import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const STYLES = {
  primary:
    'text-white bg-primary-light border-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:border-primary-light-hover focus:bg-primary-light-hover',
  secondary:
    'border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none',
};

function retrieveSizeStyle(size) {
  switch (size) {
    case 'sm': {
      return 'py-2';
    }
    case 'lg': {
      return 'p-6';
    }
    default:
      return 'py-4';
  }
}

const InternalLink = ({ to, variant, size, round, children }) => (
  <Link
    to={to}
    className={`w-full text-center whitespace-nowrap border-2 px-2 md:px-4 lg:px-6 transition rounded-md focus:outline-none 
      ${STYLES[variant]} 
      ${retrieveSizeStyle(size)} 
      ${round === 'true' ? 'rounded-full' : ''}`}
  >
    {children}
  </Link>
);

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  round: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InternalLink.defaultProps = {
  variant: 'primary',
  size: 'sm',
  round: 'false',
};

export default InternalLink;