import axios from 'axios';

import CONFIG from 'src/app/config';

const httpClient = axios.create({
  baseURL: CONFIG.DEPLOYMENT.API_URL,
  params: {
    scope: process.env.GATSBY_VERTICAL,
  },
  withCredentials: true,
});

httpClient.interceptors.request.use(
  (request) => {
    request.headers.authorization = localStorage.getItem('auth');
    return request;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorCustom = { ...error };

    const sessionLost =
      error.response && error.response.status === 401 && error.response.data === 'Session lost';

    if (sessionLost) {
      errorCustom.sessionLost = true;
    }

    return Promise.reject(errorCustom);
  }
);

const fetcher = async (uri, ...args) => httpClient.get(uri, { ...args }).then((res) => res.data);

export { httpClient, fetcher };
