import * as Yup from 'yup';

const { GATSBY_DEPLOYMENT } = process.env;

const schema = Yup.object().shape({
  VERTICAL: Yup.string().required(),
  DEPLOYMENT: Yup.object()
    .shape({
      CLIENT_ID: Yup.string().required(),
      AUTHORITY: Yup.string().url().required(),
      KNOWN_AUTHORITIES: Yup.array(Yup.string()).required(),
      REDIRECT_URI:
        GATSBY_DEPLOYMENT === 'LOCAL'
          ? Yup.string()
              .trim()
              .matches(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/)
              .required()
          : Yup.string().url().required(),
      SCOPES: Yup.array(Yup.string().url()).required(),
      API_URL:
        GATSBY_DEPLOYMENT === 'LOCAL'
          ? Yup.string()
              .matches(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/)
              .required()
          : Yup.string().url().required(),
    })
    .required(),
  FLOWS: Yup.object()
    .shape({
      SIGNIN: Yup.object()
        .shape({
          scopes: Yup.array(Yup.string().url()).required(),
          authority: Yup.string().url().required(),
        })
        .required(),
      SIGNUP: Yup.object()
        .shape({
          scopes: Yup.array(Yup.string().url()).required(),
          authority: Yup.string().url().required(),
        })
        .notRequired()
        .default(undefined),
      PROFIL: Yup.object()
        .shape({
          scopes: Yup.array(Yup.string().url()).required(),
          authority: Yup.string().url().required(),
        })
        .required(),
      PASSWORD: Yup.object()
        .shape({
          scopes: Yup.array(Yup.string().url()).required(),
          authority: Yup.string().url().required(),
        })
        .required(),
    })
    .required(),
  MSAL: Yup.object()
    .shape({
      auth: Yup.object()
        .shape({
          clientId: Yup.string().required(),
          authority: Yup.string().url().required(),
          knownAuthorities: Yup.array(Yup.string()).required(),
          redirectUri:
            GATSBY_DEPLOYMENT === 'LOCAL'
              ? Yup.string()
                  .matches(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/)
                  .required()
              : Yup.string().url().required(),
        })
        .required(),
      cache: Yup.object()
        .shape({
          cacheLocation: Yup.string().oneOf(['localStorage', 'sessionStorage']).required(),
          storeAuthStateInCookie: Yup.boolean().required(),
          secureCookies: Yup.boolean().required(),
        })
        .required(),
    })
    .required(),
});

export default schema;
