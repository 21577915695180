import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

const Text = ({ name, label, namespaces, translate, disabled, labelStyle }) => {
  const [{ value }, , { setValue }] = useField(name);
  const { t } = useTranslation(translate ? namespaces : 'common');

  return (
    <button
      type="button"
      onClick={disabled ? () => {} : () => setValue(!value)}
      className={`w-full px-1 text-sm flex items-center space-x-4 cursor-pointer focus:outline-none ${
        disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 focus:underline'
      }`}
    >
      <Field
        type="checkbox"
        name={name}
        aria-labelledby={name}
        autoComplete="off"
        disabled={disabled}
        className="flex-shrink-0 w-4 h-4 transition bg-white rounded-sm appearance-none checked:bg-primary-light ring-1 ring-gray-300 ring-offset-white ring-offset-2 hover:cursor-pointer focus:outline-none disabled:pointer-events-none"
      />
      <label
        id={name}
        htmlFor={name}
        className={`text-left ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${labelStyle}`}
      >
        {translate ? t(label) : label}
      </label>
    </button>
  );
};

Text.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.string,
};

Text.defaultProps = {
  namespaces: [],
  translate: true,
  disabled: false,
  labelStyle: '',
};

export default Text;
