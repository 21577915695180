import React from 'react';

import logo from 'src/assets/images/equilibre_logo.png';
import linkedin from 'src/assets/images/equilibre_linkedin.png';

const Footer = () => {
    return (
        <footer className='py-14' style={{
            backgroundColor: '#e5ecee'
        }}>
            <div className=' container mx-auto grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8 place-items-center md:place-items-stretch'>
                <div className='pb-4 md:pb-0'>
                    <img src={logo}
                        alt="Logo Equilibre"
                        style={{
                            height: '20px'
                        }}
                    />
                </div>
                <div className='md:text-right' style={{
                    color: '#353A60',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 600,
                    fontFamily: 'Open Sans, sans-serif'
                }}>
                    <a href='/faq/' className="mx-4">
                        FAQ
                    </a>
                    <a href='/cgu/' className="mx-4">
                        CGU
                    </a>
                    <a href='/mentions-legales/' className="mx-4">
                        Mentions légales
                    </a>
                    <a href='/rgpd/' className="mx-4">
                        Politique de protection des données
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;