/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    isAuthenticating: true,
    authenticated: false,
    token: '',
    clientId: '',
    clientName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    preferredLanguage: '',
    role: '',
    endorser: '',
  },
  reducers: {
    setAuthenticating(state, action) {
      state.isAuthenticating = action.payload;
    },
    connect: (state, action) => {
      const {
        bearer,
        isAuthenticating,
        id,
        clientId,
        clientName,
        firstName,
        lastName,
        email,
        phone,
        preferredLanguage,
        role,
        endorser,
      } = action.payload;

      localStorage.setItem('auth', bearer);
      state.token = bearer;
      state.id = `${id}`;
      state.clientId = `${clientId}`;
      state.clientName = clientName || '';
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.phone = phone;
      state.preferredLanguage = preferredLanguage;
      state.role = role ? role.toUpperCase() : 'null';
      state.endorser = endorser.toLowerCase();
      state.authenticated = true;

      if (isAuthenticating) state.isAuthenticating = isAuthenticating;
    },
    update: (state, action) => {
      const { firstName, lastName, phone } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = phone;
    },
    updateToken: (state, action) => {
      localStorage.setItem('auth', action.payload);
      state.token = action.payload;
    },
    updateEndorser: (state, action) => {
      state.endorser = action.payload;
    },
  },
});

export const {
  setAuthenticating,
  connect,
  update,
  updateToken,
  updateEndorser,
} = userSlice.actions;
export default userSlice.reducer;
