import VERTICALS from '../../constants/verticals.json';
import { FLOWS, MSAL } from '../shared';

export default {
  VERTICAL: VERTICALS.FTG,
  DEPLOYMENT: {
    LOCAL: {
      ...MSAL.LOCAL,
      API_URL: 'http://localhost:3000/api',
    },
    DEV: {
      ...MSAL.DEV,
      REDIRECT_URI: 'https://ftg-amba-dev.hs.sharecare.paris',
      API_URL: 'https://amba-back-dev.hs.sharecare.paris/api',
    },
    QA: {
      ...MSAL.QA,
      REDIRECT_URI: 'https://ftg-amba-qa.hs.sharecare.paris',
      API_URL: 'https://amba-back-qa.hs.sharecare.paris/api',
    },
    PREPROD: {
      ...MSAL.PREPROD,
      REDIRECT_URI: 'https://ftg-amba-preprod.hs.sharecare.paris',
      API_URL: 'https://amba-back-preprod.hs.sharecare.paris/api',
    },
    PROD: {
      ...MSAL.PROD,
      REDIRECT_URI: 'https://ftg.hs.sharecare.com',
      API_URL: 'https://amba-back.hs.sharecare.com/api',
    },
  }[process.env.GATSBY_DEPLOYMENT],
  FLOWS: {
    [FLOWS.SIGNIN]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_signup_signin`,
    },
    [FLOWS.SIGNUP]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_signup`,
    },
    [FLOWS.PROFIL]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_profile_editing`,
    },
    [FLOWS.PASSWORD]: {
      scopes: MSAL[process.env.GATSBY_DEPLOYMENT].SCOPES,
      authority: `${MSAL[process.env.GATSBY_DEPLOYMENT].AUTHORITY}/B2C_1_password_reset`,
    },
  },
};
