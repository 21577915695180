import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Field, ErrorMessage, useField } from 'formik';

const TextArea = ({ name, label, namespaces, translate, disabled, placeholder, onClick }) => {
  const { t } = useTranslation(translate ? namespaces : 'common');
  const [, meta] = useField(name);

  return (
    <div
      className={`w-full flex flex-col text-sm ${
        disabled ? 'opacity-10 cursor-not-allowed' : 'opacity-100'
      }`}
      onClick={onClick}
      onKeyDown={onClick}
      role="textbox"
      tabIndex={0}
    >
      { label &&
        <label id={name} htmlFor={name}>
          {translate ? t(label) : label}
        </label>
      }
      <Field
        type="textarea"
        as="textarea"
        rows="5"
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        aria-labelledby={name}
        disabled={disabled}
        className={`px-3 py-2 mt-1 rounded transition border focus:outline-none disabled:pointer-events-none ${
          meta.error && meta.touched ? 'border-red-500' : 'border-gray-300 focus:border-black'
        }`}
        style={{ minHeight: '100px', maxHeight: '24rem' }}
      />
      <ErrorMessage name={name} component="div">
        {(msg) => <div className="py-1 text-sm text-red-500">{translate ? t(msg) : msg}</div>}
      </ErrorMessage>
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

TextArea.defaultProps = {
  namespaces: [],
  placeholder: '',
  translate: true,
  disabled: false,
  onClick: null,
};

export default TextArea;