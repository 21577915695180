import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField } from 'src/app/components';

import countries from 'src/app/constants/countries.json';
import states from 'src/app/constants/states.json';

const initialValues = {
  aggrement: false,
  billing: {
    contactEmail: '',
    billingAddressee: '',
    billingAttention: '',
    billingAddresseePhone: '',
    billingAddress: '',
    billingAdditionalAddress: '',
    billingCity: '',
    billingPostalCode: '',
    billingCountry: 'US',
    billingState: 'AL',
    shippingUseBillingAddress: true,
    shippingAddressee: '',
    shippingAttention: '',
    shippingAddresseePhone: '',
    shippingAddress: '',
    shippingAdditionalAddress: '',
    shippingCity: '',
    shippingPostalCode: '',
    shippingCountry: 'US',
    shippingState: 'AL',
    preferredCurrency: 'USD',
  },
};

const schema = Yup.object().shape({
  aggrement: Yup.boolean().required('forms.required').isTrue(),
  billing: Yup.object().shape({
    contactEmail: Yup.string()
      .email('forms.format')
      .max(250, 'forms.too_long')
      .required('forms.required'),
    billingAddressee: Yup.string().required('forms.required').max(250, 'forms.too_long'),
    billingAttention: Yup.string().required('forms.required').max(250, 'forms.too_long'),
    billingAddresseePhone: Yup.string()
      .matches(
        /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/,
        'forms.format'
      )
      .max(250, 'forms.too_long')
      .required('forms.required'),
    billingAddress: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    billingAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
    billingCity: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    billingPostalCode: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    billingCountry: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    billingState: Yup.string().when('billingCountry', {
      is: (val) => val === 'US' || val === 'CA',
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingUseBillingAddress: Yup.boolean().default(true).required(),
    shippingAddressee: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingAttention: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingAddresseePhone: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string()
        .matches(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[ ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,4})?$/,
          'forms.format'
        )
        .max(250, 'forms.too_long')
        .required('forms.required'),
    }),
    shippingAddress: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingAdditionalAddress: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long'),
    }),
    shippingCity: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingPostalCode: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingCountry: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
    shippingState: Yup.string().when('shippingUseBillingAddress', {
      is: false,
      then: Yup.string().when('shippingCountry', {
        is: (val) => val === 'US' || val === 'CA',
        then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      }),
    }),
  }),
});

const Step = () => {
  const { t } = useTranslation(['dashboard', 'countries', 'states']);

  const [{ value: employees }] = useField('employees');
  const [{ value: product }] = useField('product');
  const [{ value: billing }] = useField('billing');

  console.log('product :>> ', product);

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col space-y-2">
          <span className="text-xl font-bold">Checkout</span>
          <span className="pt-4">Sharecare Health Security Pass</span>
        </div>

        <div className="space-y-6">
          <div className="flex items-center justify-center px-4 py-12 bg-purple-500 rounded-md bg-opacity-10">
            <div>
              <p className="flex items-center justify-between pt-4 space-x-12">
                <span>Employee count</span>
                <span>{employees}</span>
              </p>
              <p className="flex items-center justify-between space-x-12">
                <span>Agreement duration</span>
                <span>{`${product.nbMonths}`} months</span>
              </p>
              <hr className="mt-6 border-black" />
              <p className="flex items-center justify-between pt-6 space-x-12">
                <strong>Total agreement value**</strong>
                <strong>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  }).format(product.price)}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm">An invoice will be sent by email after you create your account.</p>
      </div>
      <div className="max-w-md pt-12 mx-auto space-y-6 lg:mx-0">
        <div className="space-y-6">
          <span className="text-xl font-bold">{t(`forms.contact.titles.billing`)}</span>
          <InputField
            name="billing.billingAttention"
            label="forms.contact.attention"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingAddressee"
            label="forms.contact.addressee"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.contactEmail"
            label="forms.contact.email"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingAddresseePhone"
            label="forms.contact.addresseePhone"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingAddress"
            label="forms.address.address"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingAdditionalAddress"
            label="forms.address.additionalAddress"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingCity"
            label="forms.address.city"
            autoComplete="city"
            namespaces={['dashboard']}
          />
          <InputField
            name="billing.billingPostalCode"
            label="forms.address.postal"
            autoComplete="zip"
            namespaces={['dashboard']}
          />
          <InputField
            type="select"
            name="billing.billingCountry"
            label="forms.address.country"
            namespaces={['dashboard']}
            options={countries.map((country) => ({
              ...country,
              label: t(`countries:${country.label}`),
            }))}
          />
          {(billing.billingCountry === 'CA' || billing.billingCountry === 'US') &&
            states[billing.billingCountry] && (
              <InputField
                type="select"
                name="billing.billingState"
                label="forms.address.state"
                namespaces={['dashboard']}
                options={states[billing.billingCountry].map((state) => ({
                  ...state,
                  label: t(`states:${billing.billingCountry}.${state.label}`),
                }))}
              />
            )}
        </div>
        <div className="pt-16 space-y-6">
          <span className="text-xl font-bold">{t(`forms.contact.titles.shipping`)}</span>
          <InputField
            type="checkbox"
            name="billing.shippingUseBillingAddress"
            label="forms.contact.same.shipping"
            namespaces={['dashboard']}
          />
          {!billing.shippingUseBillingAddress && (
            <>
              <InputField
                name="billing.shippingAttention"
                label="forms.contact.attention"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingAddressee"
                label="forms.contact.addressee"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingAddresseePhone"
                label="forms.contact.addresseePhone"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingAddress"
                label="forms.address.address"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingAdditionalAddress"
                label="forms.address.additionalAddress"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingCity"
                label="forms.address.city"
                autoComplete="city"
                namespaces={['dashboard']}
              />
              <InputField
                name="billing.shippingPostalCode"
                label="forms.address.postal"
                autoComplete="zip"
                namespaces={['dashboard']}
              />
              <InputField
                type="select"
                name="billing.shippingCountry"
                label="forms.address.country"
                namespaces={['dashboard']}
                options={countries.map((country) => ({
                  ...country,
                  label: t(`countries:${country.label}`),
                }))}
              />
              {(billing.shippingCountry === 'CA' || billing.shippingCountry === 'US') &&
                states[billing.shippingCountry] && (
                  <InputField
                    type="select"
                    name="billing.shippingState"
                    label="forms.address.state"
                    namespaces={['dashboard']}
                    options={states[billing.shippingCountry].map((state) => ({
                      ...state,
                      label: t(`states:${billing.shippingCountry}.${state.label}`),
                    }))}
                  />
                )}
            </>
          )}
        </div>
      </div>
      <div className="pt-12">
        <InputField
          type="checkbox"
          name="aggrement"
          translate={false}
          label={
            <p className="text-left">
              By completing my order, I agree to the
              <a href="/legal" target="_blank" className="pl-2 text-blue-500 underline">
                Health Security Product Agreement.
              </a>
            </p>
          }
        />
      </div>
    </>
  );
};

export { initialValues, schema };
export default Step;
