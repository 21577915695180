/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

const STYLES = {
  primary:
    'text-white bg-primary-light border-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:border-primary-light-hover focus:bg-primary-light-hover',
  secondary:
    'border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none',
};

const Button = ({ type, variant, size, loading, disabled, onClick, children, gtag }) => (
  <button
    type={type}
    onClick={() => {
      if (gtag && window.gtag) window.gtag('event', gtag);
      onClick();
    }}
    disabled={disabled || loading}
    className={`relative whitespace-nowrap w-full px-2 md:px-4 lg:px-6 border-2 transition rounded-md focus:outline-none ${
      STYLES[variant]
    } ${size === 'sm' ? 'py-2' : 'py-4'}`}
  >
    {children}
    {loading && (
      <span
        className={`absolute inset-0 flex flex-col items-center justify-center rounded-md ${
          variant === 'primary' && !loading ? 'bg-primary-light' : 'bg-gray-100'
        }`}
      >
        <svg width="18px" height="18px" viewBox="0 0 128 128" className="text-primary-light">
          <g>
            <path
              d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
              fill="currentColor"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 64 64"
              to="360 64 64"
              dur="1800ms"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      </span>
    )}
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  gtag: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'sm',
  loading: false,
  disabled: false,
  onClick: () => {},
  gtag: false,
};

export default Button;
