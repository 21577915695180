import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';

import { setAuthenticating, signOut } from 'src/app/store';
import CONFIG from 'src/app/config';
import { FLOWS } from 'src/app/config/shared';
import { Button } from 'src/app/components';

const { GATSBY_DEPLOYMENT } = process.env;

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { instance, inProgress } = useMsal();
  const { t, i18n } = useTranslation('common');

  const [dropdownOpen, setDropdownOpen] = useState(-1);

  const sign = async (signup = false) => {
    localStorage.setItem('redirect', 'true');
    dispatch(setAuthenticating(true));
    await instance.loginRedirect(CONFIG.FLOWS[signup ? FLOWS.SIGNUP : FLOWS.SIGNIN]);
  };

  const handleSignout = async () => {
    dispatch(signOut())
      .then(async () => instance.logout())
      .catch(async () => instance.logout());
  };

  return (
    <nav className="fixed inset-x-0 top-0 z-50 bg-white shadow-md">
      <div className="container">
        <div className="flex items-center justify-between h-16">
          <div>
            <Link to="/">
              <svg viewBox="0 0 200 26" className="w-32 lg:w-48">
                <path
                  d="M20.923 22.874A13.102 13.102 0 0 1 13 25.517c-7.18 0-13-5.712-13-12.759C0 5.712 5.82 0 13 0s13 5.712 13 12.758c0 3.017-1.067 5.79-2.85 7.974L29 25.517h-7l-1.077-2.643zM13 22.573c5.523 0 10-4.394 10-9.815 0-5.42-4.477-9.814-10-9.814S3 7.338 3 12.758s4.477 9.815 10 9.815zm21.572-3.193h3.17c-.27 4.296 7.881 4.443 8.017 1.235 0-3.298-6.34-1.2-9.775-4.571-2.331-2.04-2.398-8.404 4.928-8.584 1.641-.021 3.21.374 4.39 1.01v-.71h3.133v5.848H45.3v-1.089c-1.029-1.498-2.365-2.176-4.202-2.176-1.907 0-3.166.864-3.166 2.176 0 2.962 6.948 1.236 9.927 4.161 3.638 3.935.575 9.276-5.382 9.069-1.95 0-3.438-.41-4.736-1.273v.976h-3.17V19.38zM53.78 3.074h-3.245V0h6.837v9.783c2.249-1.798 4.045-2.548 5.994-2.548 2.824 0 4.964 1.385 4.964 5.36v9.783h3.096v3.074h-6.687V13.343c0-1.724-.533-3-2.212-3-1.299 0-3.132.826-5.155 2.327v9.708h3.09v3.074h-9.967v-3.074h3.285V3.074zm36.928 22.38h-6.264v-2.178c-5.026 4.567-11.685 2.419-11.912-3.15-.014-4.035 5.057-8.005 11.494-4.234-.114-2.302.828-5.516-4.01-5.735-1.72 0-3.055.715-3.858 2.104l-3.4-.904c2.85-5.886 15.033-5.51 14.856 1.313v9.71h3.094v3.073zm-6.682-6.6c-2.231-1.935-7.68-3.115-8.096 1.272.343 4.207 5.646 3.24 8.096.826v-2.099zm12.333-8.023H92.73V7.759h6.453v4.028c1.149-2.963 3.516-4.326 5.768-4.326h2.327v3.523h-1.91c-6.788.099-5.422 5.7-5.422 11.395h3.899v3.074h-11.04V22.38h3.552V10.83zm14.071 6.637c.961 6.68 8.915 6.763 11.97 2.548l3.117 1.349c-5.408 8.047-19.122 5.078-19-4.76 0-5.733 4.75-9.37 9.421-9.37 4.981 0 9.579 3.75 9.579 10.233H110.43zm11.476-2.552c-.57-2.808-3.077-4.872-5.888-4.872-2.812 0-4.942 1.912-5.475 4.872h11.363z"
                  fill="#333"
                />
                <path
                  d="M20 10.804c0 2.574-2.616 5.959-7.001 8.824C8.856 16.93 6 13.344 6 10.804c.095-4.565 5.683-4.766 6.999-2.55 1.363-2.216 6.919-2.049 7.001 2.55zm164.913 6.663c.962 6.68 8.915 6.762 11.971 2.547L200 21.363c-5.408 8.048-19.122 5.078-19-4.76 0-5.733 4.75-9.37 9.422-9.37 4.98 0 9.578 3.75 9.578 10.234h-15.087zm-14.776-6.639h-3.627V7.757h6.453v4.027c1.149-2.963 3.516-4.325 5.768-4.325h2.327v3.523h-1.91c-6.788.098-5.422 5.7-5.422 11.395h3.899v3.074h-11.04v-3.074h3.552V10.828zm-5.65 14.632h-6.264v-2.178c-5.026 4.568-11.687 2.42-11.914-3.15-.013-4.034 5.057-8.004 11.495-4.233-.113-2.302.829-5.516-4.01-5.735-1.72 0-3.054.715-3.857 2.104l-3.4-.905c2.849-5.885 15.032-5.509 14.856 1.313v9.71h3.094v3.074zm31.903-10.546c-.571-2.808-3.078-4.872-5.89-4.872-2.81 0-4.94 1.912-5.474 4.872h11.364zm-38.586 3.946c-2.23-1.934-7.68-3.114-8.095 1.273.341 4.206 5.645 3.24 8.095.826V18.86zm-13.826-4.429h-3.207c-.288-2.244-1.979-4.01-4.773-4.01-3.209 0-5.348 2.435-5.348 5.996.4 8.375 9.017 7.59 10.923 2.886l2.942 1.35c-1.567 3.486-4.545 5.322-8.482 5.322-6.26 0-9.2-5.024-9.2-9.52 0-5.098 3.932-9.22 8.859-9.22 1.949 0 3.74.6 5.08 1.723v-1.2h3.206v6.673z"
                  fill="#1ABA9C"
                />
              </svg>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-end h-16 pl-8 space-x-4">
              {user.authenticated ? (
                <>
                  <Button as="link" variant="secondary" to="/dashboard/">
                    {t('navigation.buttons.dashboard')}
                  </Button>
                  <Button onClick={() => handleSignout()}>{t('navigation.buttons.signout')}</Button>
                </>
              ) : (
                <Button
                  onClick={() => sign(false)}
                  gtag="Click_login"
                  disabled={user.isAuthenticating || inProgress !== 'none'}
                  loading={user.isAuthenticating || inProgress !== 'none'}
                >
                  {t('navigation.buttons.login')}
                </Button>
              )}
            </div>
            {GATSBY_DEPLOYMENT !== 'PREPROD' && GATSBY_DEPLOYMENT !== 'PROD' && (
              <div className="relative" role="dialog" onMouseLeave={() => setDropdownOpen(-1)}>
                <button
                  type="button"
                  onClick={() => setDropdownOpen(1)}
                  className="h-16 text-secondary hover:text-opacity-50 focus:text-opacity-50 focus:outline-none"
                >
                  <div className="flex items-center justify-center w-full h-full px-4 rounded-full">
                    <small className="capitalize">{i18n.language.slice(0, 2)}</small>
                    <svg viewBox="0 0 8 5" className="w-4 h-4 pl-1 fill-current">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.64072 4.84701L0.143713 1.35001C-0.0479042 1.14641 -0.0479042 0.835035 0.143713 0.643418C0.335329 0.451802 0.646707 0.451802 0.850299 0.643418L4 3.79312L7.1497 0.643418C7.34132 0.451802 7.66467 0.451802 7.85629 0.643418C8.0479 0.835035 8.0479 1.14641 7.85629 1.35001L4.34731 4.84701C4.15569 5.03863 3.84431 5.03863 3.64072 4.84701Z"
                      />
                    </svg>
                  </div>
                </button>
                <div
                  className="absolute right-0 bg-white border"
                  style={{ top: '4rem', display: dropdownOpen === 1 ? 'block' : 'none' }}
                >
                  <ul className="flex flex-col items-center w-48">
                    <li className="w-full">
                      <button
                        type="button"
                        tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                        onClick={() => {
                          i18n.changeLanguage('en-US');
                          setDropdownOpen(-1);
                        }}
                        className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                      >
                        English
                      </button>
                    </li>
                    <li className="w-full">
                      <button
                        type="button"
                        tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                        onClick={() => {
                          i18n.changeLanguage('fr-FR');
                          setDropdownOpen(-1);
                        }}
                        onBlur={() => setDropdownOpen(-1)}
                        className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                      >
                        Français
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
