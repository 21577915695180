import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionContext from './AccordionContext';
import { Header, Body } from './components';

const Accordion = ({ children, as, state, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen);

  switch (as) {
    case 'li':
      return (
        <AccordionContext.Provider value={state || { open, setOpen }}>
          <li>{children}</li>
        </AccordionContext.Provider>
      );
    default:
      return (
        <AccordionContext.Provider value={state || { open, setOpen }}>
          <div>{children}</div>
        </AccordionContext.Provider>
      );
  }
};

Accordion.Header = Header;
Accordion.Body = Body;

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOf(['div', 'li']),
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
  }),
  defaultOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  as: 'div',
  state: null,
  defaultOpen: false,
};

export { AccordionContext };
export default Accordion;
