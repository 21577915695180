import React, { useState } from 'react';

const Menu = ({ handler }) => {
    return (
        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto mt-4 lg:mt-0">
            <li className="nav-item mr-8">
                <a
                    className="px-3 py-2 flex items-center font-bold hover:opacity-75"
                    href="/#parcours"
                    onClick={() => handler(false)}
                >
                    Parcours
                </a>
            </li>
            <li className="nav-item mr-8">
                <a
                    className="px-3 py-2 flex items-center font-bold hover:opacity-75"
                    href="/#experts"
                    onClick={() => handler(false)}
                >
                    Experts
                </a>
            </li>
            <li className="nav-item mr-8">
                <a
                    className="px-3 py-2 flex items-center font-bold hover:opacity-75"
                    href="/#kit-deploy"
                    onClick={() => handler(false)}
                >
                    Déploiement
                </a>
            </li>
            <li className="nav-item mr-8">
                <a
                    className="px-3 py-2 flex items-center font-bold hover:opacity-75"
                    href="/faq/"
                    onClick={() => handler(false)}
                >
                    FAQ
                </a>
            </li>
            <li className="nav-item hidden lg:block">
                <a
                    className="px-5 py-2 flex items-center text-white rounded font-bold hover:opacity-75"
                    href="/#contact"
                    onClick={() => handler(false)}
                    style={{ backgroundColor: 'rgb(254, 172, 93)' }}
                >
                    Contact
                </a>
            </li>
        </ul>
    );
}

export default Menu;