import React from 'react';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

const Toggle = ({ name, label, namespaces, translate, disabled }) => {
  const [{ value }, , { setValue }] = useField(name);
  const { t } = useTranslation(translate ? namespaces : 'common');

  return (
    <div className="flex items-center space-x-2">
      <ReactToggle
        id={name}
        name={name}
        value={`${value}`}
        checked={value}
        disabled={disabled}
        onChange={(e) => setValue(e.target.checked)}
        icons={false}
      />
      <label htmlFor={name} className="select-none">
        {translate ? t(label) : label}
      </label>
    </div>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
};

Toggle.defaultProps = {
  namespaces: [],
  translate: true,
  disabled: false,
};

export default Toggle;
