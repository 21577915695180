import React from 'react';
import PropTypes from 'prop-types';

// import { ModalQuote } from 'src/app/views/static/shared';
import { Navigation, Footer } from './components';

const Layout = ({ children }) => (
  <>
    <Navigation />
    {children}
    <Footer />
    {/* <ModalQuote /> */}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;