import React from 'react';
import PropTypes from 'prop-types';

import { Navigation, Footer } from './components';

const Layout = ({ children, logo }) => (
  <>
    <Navigation logo={logo} />
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.string,
};

Layout.defaultProps = {
  logo: null,
};

export default Layout;
