import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { Modal, Button } from 'src/app/components';

const { GATSBY_VERTICAL } = process.env;

const getKeyForScope = () => {
  switch (GATSBY_VERTICAL) {
    case 'CRUISES':
      return 'welcome.cruises';
    case 'GREENSHIELD':
      return 'welcome.greenshield';
    default:
      return 'welcome.default';
  }
};

const ModalWelcome = ({ open, requestClose }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Modal open={open} requestClose={requestClose} closeOnEsc={false} closeOnOverlay={false}>
      <Modal.Body className="max-w-md px-6 py-12 mx-auto space-y-6">
        <h5 className="font-bold">{t('welcomeTitle')}</h5>
        <p className="pt-2 text-secondary">
          <Trans t={t} i18nKey={getKeyForScope()} components={{ br: <br />, strong: <strong /> }}>
            Welcome!
            <br />
            <br />
            You have successfully activated your account.
            <br />
            <br />
            In this portal, you will be able to easily manage the following:
            <br />
            - Set up your facility / facilities
            <br />
            - Add and manage additional users
            <br />
            - Configure your health security pass options
            <br />
            - View your admin dashboard
            <br />
            - Change your settings and profile
            <br />
          </Trans>
        </p>
        <div className="w-full max-w-sm">
          <Button
            size="md"
            onClick={() => {
              localStorage.setItem('newUser', 'false');
              requestClose();
            }}
          >
            {t('welcomeButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalWelcome.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
};

export default ModalWelcome;
