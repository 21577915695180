/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const Richtext = ({ content, className }) => (
  <div className={`prose ${className}`} dangerouslySetInnerHTML={{ __html: content }} />
);

Richtext.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
};

Richtext.defaultProps = {
  className: '',
};

export default Richtext;
