export default Object.freeze({
  LOCAL: {
    CLIENT_ID: 'a9211283-087c-47c0-841d-d114faed4a0f',
    AUTHORITY: 'https://sharecaredev.b2clogin.com/sharecaredev.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['sharecaredev.b2clogin.com'],
    REDIRECT_URI: 'http://localhost:6420',
    SCOPES: [
      'https://sharecaredev.onmicrosoft.com/api/read',
      'https://sharecaredev.onmicrosoft.com/api/write',
    ],
  },
  DEV: {
    CLIENT_ID: 'b26e32d2-2476-4dee-ab89-31d704fe8de3',
    AUTHORITY: 'https://sharecaredev.b2clogin.com/sharecaredev.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['sharecaredev.b2clogin.com'],
    REDIRECT_URI: 'https://amba-dev.hs.sharecare.paris',
    SCOPES: [
      'https://sharecaredev.onmicrosoft.com/fd06be8e-d420-4f9b-8d7f-09a5740ec669/read',
      'https://sharecaredev.onmicrosoft.com/fd06be8e-d420-4f9b-8d7f-09a5740ec669/write',
    ],
  },
  QA: {
    CLIENT_ID: 'fb62decb-a751-4114-83b3-43f386d6275d',
    AUTHORITY: 'https://sharecareqa.b2clogin.com/sharecareqa.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['sharecareqa.b2clogin.com'],
    REDIRECT_URI: 'https://amba-qa.hs.sharecare.paris',
    SCOPES: [
      'https://sharecareqa.onmicrosoft.com/301321a3-b9f6-472a-901b-302df88cbe22/read',
      'https://sharecareqa.onmicrosoft.com/301321a3-b9f6-472a-901b-302df88cbe22/write',
    ],
  },
  PREPROD: {
    CLIENT_ID: 'be9507c8-4a39-405c-b96a-b59a4e6f68da',
    AUTHORITY: 'https://sharecarepreprod.b2clogin.com/sharecarepreprod.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['sharecarepreprod.b2clogin.com'],
    REDIRECT_URI: 'https://amba-preprod.hs.sharecare.paris',
    SCOPES: [
      'https://sharecarepreprod.onmicrosoft.com/4c24a651-c543-4936-a1b3-5280b6855f0e/read',
      'https://sharecarepreprod.onmicrosoft.com/4c24a651-c543-4936-a1b3-5280b6855f0e/write',
    ],
  },
  PROD: {
    CLIENT_ID: '973a6c3a-c56b-43d0-b396-159ba144593a',
    AUTHORITY: 'https://healthsecurity.b2clogin.com/healthsecurity.onmicrosoft.com',
    KNOWN_AUTHORITIES: ['healthsecurity.b2clogin.com'],
    SCOPES: [
      'https://healthsecurity.onmicrosoft.com/9bb4e234-946b-40bc-8cae-cbe8882d4a7e/read',
      'https://healthsecurity.onmicrosoft.com/9bb4e234-946b-40bc-8cae-cbe8882d4a7e/write',
    ],
  },
});
