/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  TextArea,
  Number,
  Checkbox,
  Radio,
  Search,
  Select,
  Date,
  Toggle,
} from './components';

const InputField = ({ type, ...props }) => {
  switch (type) {
    case 'text':
      return <Text {...props} />;
    case 'textarea':
      return <TextArea {...props} />;
    case 'number':
      return <Number {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'radio':
      return <Radio {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'date':
      return <Date {...props} />;
    case 'toggle':
      return <Toggle {...props} />;
    default:
      return <Text {...props} />;
  }
};

InputField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'number',
    'checkbox',
    'radio',
    'select',
    'search',
    'date',
    'toggle',
  ]),
};

InputField.defaultProps = {
  type: 'text',
};

export default InputField;
