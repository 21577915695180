import React from 'react';
import PropTypes from 'prop-types';

const GRADIANTS = {
  ADMIN: 'black',
  MANAGER: '#00BFA5',
  VIEWER: '#5D6A74',
  DEFAULT: '#5D6A74',
};

const Avatar = ({ initials, role }) => (
  <div
    className="flex items-center justify-center flex-shrink-0 rounded-full w-9 h-9"
    style={{
      background: GRADIANTS[role ? role.toUpperCase() : 'DEFAULT'],
    }}
  >
    <span className="font-semibold text-white uppercase">{initials}</span>
  </div>
);

Avatar.propTypes = {
  initials: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['ADMIN', 'MANAGER', 'VIEWER', 'DEFAULT']),
};

Avatar.defaultProps = {
  role: 'DEFAULT',
};

export default Avatar;
