const formatValues = (values) => {
    const billingInformation = {
      currency: values.preferedCurrency,
      billingAddressee: values.billingAddressee,
      billingAttention: values.billingAttention,
      billingPhoneId: values.billingAddresseePhone,
      phone: { number: values.billingAddresseePhone },
      email: values.contactEmail,
      billingUseFacilityAddress: values.billingUseFacilityAddress,
      shippingUseBillingInformation: values.shippingUseBillingAddress,
    };
  
    if (!values.billingUseFacilityAddress) {
      billingInformation.billingAddress = {
        line1: values.billingAddress,
        line2: values.billingAdditionalAddress,
        city: values.billingCity,
        zipCode: values.billingPostalCode,
        country: values.billingCountry,
        state: values.billingState,
      };
      billingInformation.billingPhone = {
        number: values.billingAddresseePhone,
      };
    }
  
    if (!values.shippingUseBillingAddress) {
      billingInformation.shippingAddress = {
        line1: values.shippingAddress,
        line2: values.shippingAdditionalAddress,
        city: values.shippingCity,
        zipCode: values.shippingPostalCode,
        country: values.shippingCountry,
        state: values.shippingState,
      };
      billingInformation.shippingPhone = {
        number: values.shippingAddresseePhone,
      };
      billingInformation.shippingAddressee = values.shippingAddressee;
      billingInformation.shippingAttention = values.shippingAttention;
    }
  
    return billingInformation;
  };
  
  export default formatValues;
  