import React from 'react';
import PropTypes from 'prop-types';
import MatPagination from '@mui/material/Pagination';

const Pagination = ({ page, setPage, pages, result, size }) => (
  <div className="flex items-center justify-center py-3 mt-6 bg-white border-t border-gray-200 sm:justify-between">
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{(page - 1) * size + 1}</span> to{' '}
        <span className="font-medium">{page * size > result ? result : page * size}</span> of{' '}
        <span className="font-medium">{result}</span> results
      </p>
    </div>
    <MatPagination
      count={pages}
      page={page}
      onChange={(_, value) => setPage(value)}
      showFirstButton
      showLastButton
    />
  </div>
);

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  result: PropTypes.number.isRequired,
  size: PropTypes.number,
};

Pagination.defaultProps = {
  size: 10,
};

export default Pagination;
