import React, { useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { SWRConfig } from 'swr';
import queryString from 'query-string';
import { useMsal } from '@azure/msal-react';
import ActivityDetector from 'react-activity-detector';

import { connect, setAuthenticating, signOut, updateToken } from 'src/app/store';
import { Spinner } from 'src/app/components';
import CONFIG from 'src/app/config';
import { FLOWS } from 'src/app/config/shared';
import { httpClient, fetcher } from 'src/app/helpers';
import Layout from './views/Layout';

const REFRESH_RATE = 15 * 60000;

const Root = ({ children, pageContext }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { pathname, search, hash } = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const { i18n } = useTranslation();
  const [refreshInterval, setRefreshInterval] = useState(null);

  const refreshToken = async (interval) => {
    try {
      return await instance.acquireTokenSilent({
        ...CONFIG.FLOWS[FLOWS.SIGNIN],
        forceRefresh: interval,
        account: accounts[0],
      });
    } catch (error) {
      return null;
    }
  };

  const sign = async (payload) => {
    try {
      const { data } = await httpClient.post('/sign', {
        uid: payload.uid,
        email: payload.email,
        preferredLanguage: i18n.language,
        ref: localStorage.getItem('ref') || '',
        contractData: payload.quote,
      });
      return data;
    } catch (error) {
      return null;
    }
  };

  const signSilent = async (interval = false) => {
    try {
      if (!accounts || accounts.length <= 0) throw Error('No account provided');

      const signResponse = await refreshToken(interval);

      if (signResponse) {
        const bearer = `Bearer ${signResponse.accessToken}`;

        if (!interval) {
          const uid = signResponse.uniqueId;
          const email = signResponse.account.username;
          const quote = localStorage.getItem('quote');

          localStorage.setItem('auth', bearer);

          const data = await sign({ uid, email, quote: quote ? JSON.parse(quote) : null });

          if (data) {
            localStorage.setItem('newUser', data.newUser.toString());
            localStorage.removeItem('ref');
            localStorage.removeItem('quote');

            dispatch(connect({ ...data, bearer, isAuthenticating: false }));

            if (data.newUser && window.gtag) window.gtag('event', 'Account_creation');

            const redirectTo = localStorage.getItem('redirect');

            if (redirectTo) {
              if (redirectTo === 'true') navigate('/dashboard/', { replace: true });
              else navigate(redirectTo, { replace: true });
              localStorage.removeItem('redirect');
            }
          } else throw Error('Authentification failed');
        } else dispatch(updateToken(bearer));

        setTimeout(() => {
          dispatch(setAuthenticating(false));
        }, 1500);
      } else throw Error('Authentification expired');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(signOut())
        .then(async () => instance.logout())
        .catch(async () => instance.logout());
    }
  };

  useEffect(() => {
    const { ref } = queryString.parse(search);
    if (ref) {
      localStorage.setItem('ref', ref);
      window.history.replaceState({}, document.title, pathname);
    }
  }, []);

  useEffect(() => {
    if (inProgress === 'none') {
      if (hash.search('AADB2C90118') !== -1) {
        const redirectRequest = CONFIG.FLOWS[FLOWS.PASSWORD];
        const lang = i18n.language.slice(0, 2);

        instance.loginRedirect({
          ...redirectRequest,
          extraQueryParameters: { lang },
        });
      } else if (accounts && accounts.length > 0) signSilent();
      else {
        dispatch(setAuthenticating(false));
        localStorage.setItem('redirect', 'true');
        localStorage.removeItem('auth');
        localStorage.removeItem('newUser');
        localStorage.removeItem('quote');
      }
    }
  }, [inProgress]);

  useEffect(() => {
    if (user.authenticated && !refreshInterval) {
      const forceRefresh = setInterval(signSilent, REFRESH_RATE, true);
      setRefreshInterval(forceRefresh);
    }

    return () => {
      clearInterval(refreshInterval);
      setRefreshInterval(null);
    };
  }, [user.authenticated]);

  useLayoutEffect(() => {
    if (
      (pathname === '/support' || pathname.startsWith('/dashboard')) &&
      !user.authenticated &&
      inProgress === 'none' &&
      !user.isAuthenticating
    ) {
      if (localStorage.getItem('signout') !== 'true') {
        localStorage.setItem('redirect', pathname);
        instance.loginRedirect(CONFIG.FLOWS[FLOWS.SIGNIN]);
      } else {
        localStorage.removeItem('signout');
        navigate('/', { replace: true });
      }
    }
  }, [pathname, inProgress, user.isAuthenticating, user.authenticated]);

  if (typeof window !== 'undefined' && user.isAuthenticating) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="h-12 text-primary-light">
          <Spinner />
        </div>
      </div>
    );
  }

  const handleUserIdle = () => {
    // console.log('The user is loged out');

    dispatch(signOut())
      .then(async () => instance.logout())
      .catch(async () => instance.logout());
  };

  return (
    <SWRConfig value={{ fetcher }}>
      {user.authenticated && (
        <ActivityDetector isActive timeout={1000 * 60 * 30} signOut={handleUserIdle} />
      )}
      <Layout dashboardLayout={pageContext.dashboardLayout}>{children}</Layout>
    </SWRConfig>
  );
};

Root.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    dashboardLayout: PropTypes.bool,
  }),
};

Root.defaultProps = {
  pageContext: {
    dashboardLayout: false,
  },
};

export default Root;
