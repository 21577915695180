/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { GenericButton, InternalLink } from './components';

const Button = ({ as, children, ...props }) => {
  switch (as) {
    case 'button':
      return <GenericButton {...props}>{children}</GenericButton>;
    case 'link':
      return <InternalLink {...props}>{children}</InternalLink>;
    default:
      return <GenericButton {...props}>{children}</GenericButton>;
  }
};

Button.propTypes = {
  as: PropTypes.oneOf(['button', 'link']),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  as: 'button',
};

export default Button;
